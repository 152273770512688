import React from 'react';
import logo from '../assets/logo.jpg'; // Import your logo image

const Header = () => {
  return (
    <header className="header">
      <a href="/">
        <img src={logo} alt="Logo" className="logo" />
      </a>
      <a href="/" className="visual-diary-link">
        <h1>Visual Diary</h1>
      </a>
      {/* Add navigation links or branding elements here */}
    </header>
  );
}

export default Header;
