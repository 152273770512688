import { initializeApp } from "firebase/app";
import { getFirestore, serverTimestamp } from "firebase/firestore";
import { getStorage } from "firebase/storage"

var firebaseConfig = {

  apiKey: "AIzaSyDmZewWxaJ0AYKxGa9HlfBBkXDLzITJyDI",

  authDomain: "momo-albumapp.firebaseapp.com",

  projectId: "momo-albumapp",

  storageBucket: "momo-albumapp.appspot.com",

  messagingSenderId: "792574369561",

  appId: "1:792574369561:web:66e07aaffdbdbd59faef88"

};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const projectFirestore= getFirestore(app);
const projectStorage = getStorage(app);
const timestamp = serverTimestamp();

export { projectStorage, projectFirestore, timestamp };